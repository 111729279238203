import "$styles/index.css"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

window.addEventListener('load', (event) => {
  document.getElementById('view-listings-link').addEventListener('click', () => {
    fathom.trackEvent('view listings click');
  });
});

console.info("Bridgetown is loaded!")
